import { DarkModeContext } from "../../contexts/darkModeContext";
import { useContext, useState } from "react";
import Dropdown from "../Dropdown/Dropdown"


import "./header.scss"
const Header = ({Drop}) => {
    const { dispatch } = useContext(DarkModeContext);
    const [showDropdown, setShowDropdown]  = useState(false)

    return (
    <header className="header">
    <nav className=" flex items-center justify-cente  p-4">


    <div className="flex-1"></div>
    <Dropdown Drop={Drop}/>

    {/* <div className="hidden lg:flex"> */}
    <div className=" flex flex-1 flex-row">
        <button id="theme-toggle" type="button" className="hidden lg:inline-block lg:ml-auto py-1.5 px-3 m-1 text-center bg-gray-100 border border-gray-300 rounded-md text-black  hover:bg-gray-100 dark:text-gray-300 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700">          
                {/* <svg 
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="#9ca3af"
                    viewBox="0 0 20 20"
                >
                    <path d="M17.293 13.293A8 8 0 0 1 6.707 2.707a8.001 8.001 0 1 0 10.586 10.586z" />
                </svg> */}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#9ca3af"
                    width={20}
                    height={20}
                    viewBox="0 0 20 20">
                    <path
                    fillRule="evenodd"
                    d="M10 2a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1zm4 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zm-.464 4.95.707.707a1 1 0 0 0 1.414-1.414l-.707-.707a1 1 0 0 0-1.414 1.414zm2.12-10.607a1 1 0 0 1 0 1.414l-.706.707a1 1 0 1 1-1.414-1.414l.707-.707a1 1 0 0 1 1.414 0zM17 11a1 1 0 1 0 0-2h-1a1 1 0 1 0 0 2h1zm-7 4a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0v-1a1 1 0 0 1 1-1zM5.05 6.464A1 1 0 1 0 6.465 5.05l-.708-.707a1 1 0 0 0-1.414 1.414l.707.707zm1.414 8.486-.707.707a1 1 0 0 1-1.414-1.414l.707-.707a1 1 0 0 1 1.414 1.414zM4 11a1 1 0 1 0 0-2H3a1 1 0 0 0 0 2h1z"
                    clipRule="evenodd"
                    />
                </svg>
        </button>

        {/* <a href="https://tailwindflex.com/playground">
            <button className=" py-1.5 px-3 m-1 text-center bg-gray-700 border rounded-md text-white  hover:bg-gray-500 hover:text-gray-100 dark:text-gray-200 dark:bg-gray-700 hidden lg:block">
                    Tailwind Playground
            </button>
        </a>

        <div>
            <span className="hidden" id="util_data" data="{{ json_encode($util_data) }}"></span>
            <a className=" py-1.5 px-3 m-1 text-center bg-gray-100 border border-gray-300 rounded-md text-black  hover:bg-gray-100 dark:text-gray-300 dark:bg-gray-700 hidden lg:inline-block "
                href="https://tailwindflex.com/login">
                Sign In
            </a>
        </div> */}

    </div>

</nav>
</header>



        )
}

export default Header