import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    aria-hidden="true"
    viewBox="0 0 24 24"
    width={props.width}
    heigth={props.width}
  >
    <path
      stroke="none"
      d="M12 2.5A9.5 9.5 0 0 0 2.5 12a9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5A9.5 9.5 0 0 0 12 2.5ZM8.469 7.305 17.853 12 8.47 16.695Z"
      style={{
        opacity: 1,
        fill: "#fff",
        fillRule: "evenodd",
        stroke: "none",
        strokeWidth: 3.26071,
        strokeLinecap: "round",
      }}
    />
  </svg>
)
export default SvgComponent
