import animationa from '../../assets/img/animation.jpg';
import animation from '../../assets/img/webdesign.gif';
import uav from '../../assets/img/tumblr.gif';
import setup from '../../assets/img/my-setup.jpg';

const Heroe = () => {
    return(
        // <div className="container flex flex-col px-10 py-4 mx-auto space-y-6 md:h-128 md:py-16 md:flex-row md:items-center md:space-x-6 mb-20">
        //     <div className="flex flex-col lg:flex-row">
        //         <div className="w-full lg:w-1/3 p-10 prose 2xl:mt-20">
        //             <h1 className="text-7xl text-gray-600 leading-tight">Hello there!</h1>
        //             <h1 className="text-5xl text-gray-600">I'm Jose Tolentino</h1>   
        //             <p className="text-2xl text-gray-600 font-light mt-8 leading-relaxed">
        //                 Welcome to my portfolio, please use
        //                 the dropdown box to filter projects by category.
        //                  This site is on development and I'm still recording the video promo of other projects to upload them here, stay tunned :) 
        //             </p>

        //         </div>
        //         <div className="w-full lg:w-2/3  px-10  " >
        //             <img src={uav} className="rounded-xl"  style={{height:800, width:800}}/>
        //         </div>
        //     </div>
        // </div>
        <div className=" text-gray-600 py-20">
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col gap-8 lg:flex-row items-center">
            <div className="lg:w-1/2">
                <h1 className="text-4xl font-bold leading-tight mb-4">Jose Tolentino</h1>
                <h2 className="text-2xl mb-8">Software Engineer</h2>
                <p className="text-xl mb-8">Harnessing Cutting-Edge Technologies To Create Smart & Scalable Solutions</p>
 
                <div className="newsletter-card">
                        <input className="newsletter-input" type="email" placeholder="Your Email" />
                        <button className="newsletter-button">Subscribe</button>
                      </div>
            </div>
            <div className="lg:w-1/2 lg:ml-12">
                <img src={setup} style={{width: "800px"}} alt="Tailwind CSS" className="rounded-lg shadow-lg hover:shadow-xl transition duration-200"/>
            </div>
        </div>
    </div>
</div>
)}

export default Heroe