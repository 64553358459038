import React from "react";
import {useState, useRef} from "react"
import "./modal.scss"
import  Play  from "../../assets/svg/Play"
import {useEffect} from "react"

// const Modal = ({theref}) => {
const Modal = ({videoSource}) => {

  const [showModal, setShowModal] = useState(false);
  const [isIframe, setIsIframe] = useState(false);
  const handleClick = () => {
    setShowModal(!showModal);
  }
  
  const handleIFrameLoad = (e) => {
    setIsIframe(true)
    console.log(e.target)
  }

  return (
    <div>
      <button
        className="flex select-none items-center w-50 gap-3 rounded-lg bg-gray-800 py-4 px-6 text-center align-middle font-sans text-sm font-bold uppercase text-white shadow-md shadow-gray-500/20 transition-all hover:shadow-lg hover:shadow-gray-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button"
        onClick={handleClick}
      >
        <Play width={25}/>
        Play Promo
      </button>
      {showModal && (
        <div className="modal" onClick={ () => {setShowModal(false)} }>
          <div className="model-content" onClick={(e) => {   e.stopPropagation();   }}>
            <div className="relative flex flex-col items-end w-auto my-6 mx-auto max-w-3xl">
            
                {/* <video className="z-10" style={{borderRadius: "10px"}} src={videoSource} controls controlsList="nodownload"/> */}
                
                <iframe width="560" height="315" onLoad={handleIFrameLoad} src={videoSource} allow="autoplay; fullscreen; picture-in-picture; clipboard-write"  title={videoSource}></iframe>
                {/* <VideoItem src={videoSource}/> */}
                {isIframe && <button type="button" className="absolute btn-close z-20 mr-0 -mt-4" onClick={handleClick}><span className="icon-cross "/></button>}
                

                {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/Qnpi6e_XMQ8?si=4t88wKUYqKmoIT-N" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen ></iframe> */}
            </div>
          </div>
        </div>
      ) }
    </div>
  );
}

export default Modal