const CVCapi = () =>  {
    return (
        <div>

           <iframe title="control" className="doc" src="https://docs.google.com/viewer?srcid=1Vv1WtOXk973duLiUL4cTmVrgPIzOTMTffhFgT1rqATA&pid=explorer&embedded=true"></iframe>

        </div>
    );
}
export default CVCapi
