import { useState, useEffect } from 'react';

const Stack = ({ stack }) => {
    const [thelink, setThelink] = useState('');
    const fetchData = async () => { 
        try{
            const response = await fetch(`../../assets/img/icons/${stack}-original.svg`);
        var thePath1 = require(`../../assets/img/icons/${stack}-original.svg`)
        setThelink(thePath1);
        }
        catch(e){
            try{
                const response = await fetch(`../../assets/img/icons/${stack}-plain.svg`);
                var thePath2 = require(`../../assets/img/icons/${stack}-plain.svg`)
                setThelink(thePath2);
            }
            catch(e){
            }
        }
    }
    useEffect(() => {
        fetchData();
      }, []);
    return <img style={{ height: 32}} src={thelink} />;
};

export default Stack;





