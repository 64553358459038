const CVShaped = () =>  {
    return (
        <div>
           {/* <iframe className="doc" src="https://docs.google.com/gview?url=http://writing.engr.psu.edu/workbooks/formal_report_template.doc&embedded=true"></iframe> */}
           {/* <iframe className="doc" src="https://docs.google.com/gview?url=https://docs.google.com/document/d/e/2PACX-1vTYLBUOTebLka8cTJKhD6pZFul9cPB_DwQJMuHE9fQGI3mu_IJQ0H7yfQSdDEFLlbuqBjnXlNaFVMJB&embedded=true"></iframe> */}

           <iframe title="shaped" className="doc" src="https://docs.google.com/viewer?srcid=1172zJrqBldqQ1UElmY6CwCN8hIMopl3mlozQsLsklws&pid=explorer&embedded=true"></iframe>

           {/* <iframe className="doc" src="https://docs.google.com/gview?url=embedded=true"></iframe> */}

           {/* <iframe className="doc" src="http://docs.google.com/gview?a=v&pid=explorer&chrome=false&api=true&embedded=true&srcid=1172zJrqBldqQ1UElmY6CwCN8hIMopl3mlozQsLsklws&hl=en&embedded=true" frameborder="100"></iframe> */}

           {/* <iframe className="doc" src="https://docs.google.com/viewer?srcid=1172zJrqBldqQ1UElmY6CwCN8hIMopl3mlozQsLsklws&pid=explorer&efh=false&a=v&chrome=false&embedded=true"></iframe> */}
        </div>
    );
}
export default CVShaped


// https://docs.google.com/document/d/1172zJrqBldqQ1UElmY6CwCN8hIMopl3mlozQsLsklws/edit?usp=sharing
// 1172zJrqBldqQ1UElmY6CwCN8hIMopl3mlozQsLsklws  style="width:600px; height:500px;" frameborder="0"

// https://docs.google.com/document/d/1172zJrqBldqQ1UElmY6CwCN8hIMopl3mlozQsLsklws/edit?usp=sharing

// https://drive.google.com/drive/u/0/folders/1AGHDuqftX0ivYqFx1kvh-KNRWyRVkyaZ


// https://docs.google.com/gview?url=http://writing.engr.psu.edu/workbooks/formal_report_template.doc&embedded=true


// https://docs.google.com/gview?url=https://drive.google.com/drive/u/0/folders/1AGHDuqftX0ivYqFx1kvh-KNRWyRVkyaZ&embedded=true



