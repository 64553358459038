import Stack from "./Stack";

const StacksTable = ({stacks}) => {

    const TheStacks = [];
    for (var i = 0; i < stacks.length; i += 4) {
      let dat = [];
      var R = i + 4;
      var j = i;
      while (j < R && typeof stacks[j] !== "undefined") {
        dat.push(stacks[j]);
        j += 1;
      }
      TheStacks.push(dat);
    }
    return(
        <div className="flex justify-center py-2">
        {/* <table style={{ width: 400, textAlign: "center", marginBottom: 20,  }}> */}
        <table className="text-center  md:w-8/12 lg:w-11/12 ">
          <tbody>
            {TheStacks.map((e, i) => {
              return (
                <tr>
                  {e.map((a) => {
                    return (
                      <td
                        style={{
                          width: 100,
                          height: 100,
                          justifyContent: "center",
                        }}
                        key={`${a}`}
                      >
                        <div className="flex justify-center">
                          <Stack stack={a} />
                        </div>

                        {/* Capitalize first letter */}
                        <span>{`${
                          a.charAt(0).toUpperCase() + a.slice(1)
                        }`}</span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    )
}

export default StacksTable