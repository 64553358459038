
const CVLive = () =>  {
    return (
        <div>

            <iframe title="live" className="doc" src="https://docs.google.com/viewer?srcid=1P_cYEMIu57i36ZskPUf0hlHG4AKN2KPCeaq3lkiPyb4&pid=explorer&embedded=true"></iframe>

        </div>
    );
}
export default CVLive

