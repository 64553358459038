const CVBego = () =>  {
    return (
        <div>

           <iframe title="bego" className="doc" src="https://docs.google.com/viewer?srcid=1XewlKaRZHRBBxJ9WQO88NiECOCcZ5XcQcquPlP_7Tf4&pid=explorer&embedded=true"></iframe>

        </div>
    );
}
export default CVBego
// https://docs.google.com/document/d/1XewlKaRZHRBBxJ9WQO88NiECOCcZ5XcQcquPlP_7Tf4/edit?usp=sharing