const CVCapi = () =>  {
    return (
        <div>

            <iframe title="bio" className="doc" src="https://docs.google.com/viewer?srcid=1WZKXSmo2HysHquw8olhsMflgtM4ixIAwOEvDlRfYE5k&pid=explorer&embedded=true"></iframe>

        </div>
    );
}
export default CVCapi

