const CVCodecraters = () =>  {
    return (
        <div>

           <iframe title="control" className="doc" src="https://docs.google.com/viewer?srcid=1ZJKqMiaDrOifu1PiCnjgqX-Uthc5AvsR0_Wx-fT-6eM&pid=explorer&embedded=true"></iframe>

        </div>
    );
}
export default CVCodecraters

