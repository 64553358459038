import Heroe from "../Heroe/Heroe"
import ProjectArea from "../ProjectArea/ProjectArea"
import {useRef} from "react"
//const Content = ({theref}) => {
    const Content = ({DropHere}) => {
    return(
        <div>
            <Heroe />
            {/* <ProjectArea theref={theref} /> */}
            <ProjectArea compareTag={DropHere} />
        </div>
    )
}

export default Content