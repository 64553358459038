const Web = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      aria-hidden="true"
      className="h-5 w-5"
      viewBox="0 0 24 24"
    >
      <circle
        cx={12}
        cy={12}
        r={8.536}
        // style={{
        //   fill: "none",
        //   fillRule: "evenodd",
        //   strokeWidth: 2,
        //   strokeDasharray: "none",
        // }}
      />
      <ellipse
        cx={11.966}
        cy={12.005}
        rx={3.101}
        ry={8.496}
        // style={{
        //   fill: "none",
        //   fillRule: "evenodd",
        //   strokeWidth: 1.86133,
        //   strokeDasharray: "none",
        // }}
      />
      <path
        d="M4.035 9.438h16.222M4.035 14.562h16.222"
        // style={{
        //   fill: "none",
        //   fillRule: "evenodd",
        //   strokeWidth: 2,
        //   strokeDasharray: "none",
        // }}
      />
    </svg>
  )
  export default Web