const CVCapi = () =>  {
    return (
        <div>

           <iframe title="capi" className="doc" src="https://docs.google.com/viewer?srcid=1_fEOnrQUrN4oZLwsNlCIm5ALDD7Y7lRQ_wypVi8Mcxc&pid=explorer&embedded=true"></iframe>

        </div>
    );
}
export default CVCapi

// https://docs.google.com/document/d/1_fEOnrQUrN4oZLwsNlCIm5ALDD7Y7lRQ_wypVi8Mcxc/edit?usp=sharing
// https://docs.google.com/document/d/1_fEOnrQUrN4oZLwsNlCIm5ALDD7Y7lRQ_wypVi8Mcxc/edit?usp=sharing