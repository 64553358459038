import { Select, Option } from '@material-tailwind/react';
import { useState, useEffect } from 'react';

export default function Dropdown({Drop}) {

    // const [selection, setSelection] = useState("");
    // const handleChange = (e) => {
    //     setSelection(e)
    //     Drop(e)
    // }


    return (
        <div>
        <div className="w-72">
          {/* <Select label="Filter by" value={selection} onChange={handleChange}> */}
          <Select label="Filter by" onChange={(e) => Drop(e)}>
            <Option value="">All</Option>
            <Option value="mobileapp">Mobile App</Option>
            <Option value="website">Website</Option>
            <Option value="javascript">Javascript</Option>
            <Option value="python">Python</Option>
            <Option value="django">Django</Option>
            <Option value="react">React</Option>
            <Option value="ai">AI</Option>
            <Option value="payment">Payment System</Option>
            <Option value="phoenix">Phoenix</Option>
            <Option value="spring boot">Spring Boot</Option>
            <Option value="go">Go</Option>

          </Select>
        </div>
        </div>
      );
}
