import { ReactComponent as Notifications } from '../../assets/img/notifications.svg'
import mylog from '../../assets/img/mylog.svg'


const Sidebar = () => {

    return (
        // <aside className="sidebar flex flex-col items-center bg-blue-100 text-gray-700 shadow h-full">
        <aside className="sidebar flex flex-col items-center text-gray-700  h-full">
        {/* <!-- Side Nav Bar--> */}

        <div className="flex items-center w-full mt-4">
            <a className=" mx-auto" href="">
                <img className="h-18 w-6 mx-auto" src={mylog} />
                {/* <Drawing /> */}
            </a>
        </div>

        <ul>
            {/* <!-- Items Section --> */}
            <li className="hover:bg-gray-100 -rotate-90 ">
            <a href="/" className="py-8 mt-8 font-bold flex flex justify-center items-center w-full focus:text-gray-800" >
                Software
            </a>
            </li>

            <li className="hover:bg-gray-100 -rotate-90 ">
            <a href="/hardware" className="py-8  mt-8 font-bold flex flex justify-center items-center w-full focus:text-gray-800" >
                Hardware
            </a>
            </li>


        </ul>


        <div className="mt-auto h-28 flex flex-col items-center w-full">
            {/* <!-- Action Section --> */}
            <button className="h-16 w-10 flex justify-center items-center focus:outline-none" onClick={() => window.open("https://github.com/jatolentino/", "_blank")}>
                {/* <i class="fa-brands fa-github"></i> */}
                <i className="fab fa-github text-2xl text-black hover:text-gray-500 hover:shadow-2xl"></i>
            </button>
            <button className="h-16 w-10 flex justify-center items-center focus:text-gray-500 hover:text-gray-500 focus:outline-none">
                <Notifications />
            </button>
        </div>
        </aside>

)}
export default Sidebar