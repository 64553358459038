const CVDreamcraft = () =>  {
    return (
        <div>

           <iframe title="control" className="doc" src="https://docs.google.com/viewer?srcid=1SLhcQndq2GNd4ODO8Afe1I4NA7_zasbWvlDkWD0RJ-U&pid=explorer&embedded=true"></iframe>

        </div>
    );
}
export default CVDreamcraft
