const CVRappi = () =>  {
    return (
        <div>

            <iframe title="rapi" className="doc" src="https://docs.google.com/viewer?srcid=1Gcv64j_6VWPpLJZ_L_NcpNNWLXU33vJ0oqsHwbaelJ0&pid=explorer&embedded=true"></iframe>

        </div>
    );
}
export default CVRappi
