
const CVTeam = () =>  {
    return (
        <div>

            <iframe title="team" className="doc" src="https://docs.google.com/viewer?srcid=1w0hPEEC-Mtzd0lNb_DuOdE1L9VXHYm1QLmNnkq0dQBs&pid=explorer&embedded=true"></iframe>

        </div>
    );
}
export default CVTeam
