const Github = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      aria-hidden="true"
      className="h-5 w-5"
      viewBox="0 0 24 24"

    >
      <path
        stroke="none"
        d="M12 2.5a9.742 9.742 0 0 0-3.079 18.983c.486.09.644-.212.644-.469v-1.813c-2.71.59-3.274-1.15-3.274-1.15-.443-1.125-1.082-1.425-1.082-1.425-.883-.604.068-.591.068-.591.978.068 1.493 1.004 1.493 1.004.868 1.488 2.278 1.058 2.834.81.087-.63.34-1.06.618-1.303-2.163-.247-4.437-1.083-4.437-4.814a3.77 3.77 0 0 1 1.003-2.614c-.1-.246-.434-1.237.095-2.578 0 0 .818-.262 2.68.998A9.341 9.341 0 0 1 12 7.21a9.37 9.37 0 0 1 2.44.328c1.86-1.26 2.676-.998 2.676-.998.53 1.341.196 2.333.096 2.578.625.681 1.002 1.55 1.002 2.614 0 3.741-2.278 4.565-4.447 4.806.35.302.668.894.668 1.804v2.672c0 .26.156.564.65.468a9.743 9.743 0 0 0 6.655-9.242c0-5.379-4.361-9.74-9.74-9.74z"
        style={{
          fill: "#000",
          stroke: "none",
          strokeWidth: 1.62336,
        }}
      />
    </svg>
  )
  export default Github
  