import { useContext, useState } from "react";
import { DarkModeContext } from "../contexts/darkModeContext";
import Sidebar from '../components/Sidebar/Sidebar'
import Content from '../components/Content/Content'
import Header from "../components/Header/Header"
import "../assets/css/dark/dark.scss";
import "../assets/css/app.scss";


const Home = () =>  {
  const { darkMode } = useContext(DarkModeContext);
  const [message, setMessage] = useState("")
  const Drop = (msg) => {
    setMessage(msg)
  }
  return (
    <div  className={darkMode ? "app dark" : "app"}>
        <div className="flex h-screen w-screen  ">
        <Sidebar />
        <div className="projects flex flex-col flex-1 h-screen overflow-y-scroll">
            <main className="flex-1">
            <div>
                <Header Drop={Drop}/>
                <Content DropHere={message}/>
            </div>
            </main>
        </div>

        </div>
  </div>
  );
}

export default Home;
