
import phone from '../../assets/img/miniphone.png';
import pc from '../../assets/img/minipc.png';
import "./project.scss"
import StacksTable from './StacksTable'
import  Web  from "../../assets/svg/Web"
import  Github from "../../assets/svg/Github"
import Modal from "../Modal/Modal"


const ImageSequence = ({image1, image2, image3}) => {
  return (
      <div className="svg-container">
      <svg width="332" height="720" viewBox="0 0 332 720">
        <image width="332" height="720" id="frame1" xlinkHref={image1} x="0" y="0" />
        <image width="332" height="720" id="frame2" xlinkHref={image2} x="332" y="0"   />
        <image width="332" height="720" id="frame3" xlinkHref={image3} x="332" y="720" />
      </svg>
  </div>
  )
}

const Project = ({title, stacks, projectDesc, device, deviceImage, website, projectUrl, videoSource, tags, compareTag}) => {

  const theDevice = device === "pc" ? pc : phone
  const deviceStyle = device === "pc" ? "computer" : "phone"
  const deviceFrameStyle = device === "pc" ? "computerBorder" : "phoneBorder"
  const deviceImageStyle = device === "pc" ? "imgPc" : "imgPhone"
  let isSequence = deviceImage.length === 1 ? false : true;
 

  
  let showProject = ""
  if(compareTag === ""){
    showProject = true
  }else{
    showProject  = tags.includes(compareTag)
  }



  return ( showProject && 
    // <div className="container flex flex-col px-6 py-4 mx-auto space-y-6 md:h-128 md:py-16 md:flex-row md:items-center md:space-x-6">
    <section id={title}>
    <div className="container flex flex-col gap-2 px-6 py-4 mx-auto space-y-6 md:h-128 md:py-16 md:flex-row md:items-center md:space-x-6"> 
      
      {/* <div className="flex flex-col items-center w-full md:flex-row md:w-1/2"> */}
      <div className="flex flex-col items-center w-full">
        
        <div className="max-w-lg md:mx-12 md:order-2">
          <h1 className="text-3xl font-medium tracking-wide text-gray-800 dark:text-white md:text-4xl">
            {title}
          </h1>
          <p className="mt-4 text-gray-600 dark:text-gray-300">
            {projectDesc}
          </p>

          <StacksTable stacks={stacks}/>
          {/* <div className="flex flex-row gap-4 items-center">
            <button className="bg-indigo-500 hover:bg-cyan-600">ello</button>
            <button>Hello</button>
          </div> */}

          <div className="flex justify-center gap-4 ">
            
            <button
              className="flex select-none items-center w-50 gap-3 rounded-lg bg-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-800/20 transition-all hover:shadow-lg hover:shadow-gray-800/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              type="button"
              data-ripple-light="true"
              onClick={() => window.open(website, '_blank')} 
            >
              {/* style={{stroke:"blue"}} */}
              {/* <Web  className="stroke-blue-500"/> */}
              <Web />
              Visit Website
            </button>

            <button
              className="flex select-none items-center w-50 gap-3 rounded-lg border border-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-gray-800 transition-all hover:opacity-75 focus:ring focus:ring-gray-900 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              type="button"
              data-ripple-dark="true"
              onClick={() => window.open(projectUrl, '_blank')} 
            >
              View Project
              <Github />
            </button>

          </div>



        </div>
      </div>


    <div className="flex flex-col items-center w-full gap-4">
        <div className={deviceStyle}>
            <img className={deviceFrameStyle} src={theDevice} alt=""/>
            {
              isSequence === false ?            
                ( <img className={deviceImageStyle} src={deviceImage[0]} alt=""/>)
             :
              (<ImageSequence image1={deviceImage[0]} image2={deviceImage[1]} image3={deviceImage[2]} />)
            }
        </div> 

        <div className="flex justify-center">
          <Modal videoSource={videoSource}/>
        </div>
    </div>
    
  </div>
  </section>
  );
};

export default Project;
