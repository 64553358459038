
const CVLiterably = () =>  {
    return (
        <div>

           <iframe title="capi" className="doc" src="https://docs.google.com/viewer?srcid=1bceNwf9NXLO716KtDKwPcs3gr3Ie7OGmdyao2MdkiHI&pid=explorer&embedded=true"></iframe>

        </div>
    );
}
export default CVLiterably

