
import './App.css';
import {TheRoutes} from './Routes'

function App() {
  return (
    <TheRoutes/>
  );
}

export default App;
